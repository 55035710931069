import { useMemoizedContentGetter } from 'utils/contentful'
import { Typography, MediaImageStandard, LinkButton } from 'components/Contentful'
import { BLOCKS } from '@contentful/rich-text-types'
import Section from 'components/Section'
import styles from './CloserStatement.module.scss'
import { Grid } from '@achieve/sunbeam'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'

function CloserStatement({ content }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.xl)

  const { title, image, button, altCta } = useMemoizedContentGetter(content, [
    'title',
    'image',
    'button',
    'altCta',
  ])

  return (
    <Section className={styles['closer-statement-section']} data-testid="closer-statement-section">
      <Grid
        container
        direction={isMobile ? 'column' : 'row'}
        alignItems="center"
        className={styles['outer-grid-container']}
        data-testid="closer-statement-outer-grid-container"
      >
        <Grid
          item
          xs={8}
          xl={6.5}
          className={styles['grid-item-text']}
          data-testid="closer-statement-grid-item-text"
        >
          <div className={styles['text-container']} data-testid="closer-statement-text-container">
            <Typography
              content={title?.textContent}
              variant="displayM20"
              fontWeight="bold"
              className={styles['title']}
            />
            <div className={styles['cta-container']}>
              {button && (
                <LinkButton
                  className={styles['button']}
                  content={button}
                  typographicOptions={{
                    variantOverride: {
                      [BLOCKS.PARAGRAPH]: isMobile ? 'displayXS30' : 'displayS11CTA',
                    },
                    fontWeight: 'bold',
                    'data-testid': 'closer-statement-button-text',
                  }}
                  size={isMobile ? 'medium' : 'small'}
                  color="secondary"
                  data-testid="closer-statment-button"
                  track={{
                    click_id: button.linkText?.content[0]?.content[0]?.value,
                    list_name: 'Product Page Closer',
                  }}
                />
              )}
              {Object.hasOwnProperty.call(altCta, 'textContent') && (
                <Typography
                  content={altCta.textContent}
                  variant="displayXS30"
                  fontWeight="bold"
                  className={styles['alt-cta']}
                  data-testid="closer-statement-text-area-alt-cta"
                />
              )}
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={4}
          xl={5.5}
          className={styles['grid-item-image']}
          data-testid="closer-statement-grid-item-image"
        >
          <MediaImageStandard
            layout="fill"
            objectFit="contain"
            objectPosition="center bottom"
            height={isMobile ? 250 : 350}
            content={image?.mediaContent}
            alt={image?.mediaAltText}
            data-testid="closer-statement-image"
            placeholder={'empty'}
          />
        </Grid>
      </Grid>
    </Section>
  )
}
export { CloserStatement }
export default CloserStatement
